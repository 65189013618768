@import "../../../../global.scss";

.portfolioList{
    font-size: 14px;
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        margin-right: 20px;
    }

    &.active{
        background-color: #00AB5B;
        color: white;
    }
}