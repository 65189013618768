.app {
    height: 100vh;
    color:#272d2f;

    .sections{
        width: 100%;
        height: calc(100vh - 70px);
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none;
        margin: 0;
        padding: 0;
        transition: all 2s ease-in;

        &::-webkit-scrollbar{
            display: none;
        }

        > * {
            width: 100vw;
            height: calc(100vh - 70px);
            scroll-snap-align: start;
        }
    }
}