@import "../../global.scss";

.topbar {
  width: 100%;
  height: 70px;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 2s ease-in;
  overflow: visible;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      padding: 5px 10px;
    }

    .left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
      }
    }
    .right {
      height: auto;

      .hamburger {
        overflow: hidden;
        width: 32px;
        height: 25px;
        display: flex;
        padding: 0;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }
  }
  .center {
    // position: fixed;
  }

  &.active {
    background-color: $mainColor;
    color: white;

    .logo {
      color: white !important;
    }

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
