@import "../../global.scss";
.sidebar {
  width: 60px;
  height: 22%;
  position: fixed;
  bottom: 0;
  left: 20px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
  border-style: groove;
  padding-bottom: 20px;
  border-bottom: none;

  .itemContainer {
    cursor: pointer;
  }

  @include mobile {
    left: 0;
    border-left: none;
    border-radius: 0px 10px 0px 0px;
    width: 50px;

  }
}
