@import "../../../global.scss";

.portfolio {
  display: flex;
  flex-direction: column;

  .top {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      flex-direction: column;
    }
    h1 {
      font-size: 50px;
      margin-right: 30px;
      @include mobile {
        font-style: 20px;
      }
    }

    ul {
      max-height: fit-content;
      margin: 10px 10px 20px 10px;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(203, 231, 205);
      border-radius: 10px;

      @include mobile {
        flex-wrap: wrap;
        justify-content: center;
        margin: 0;
        margin: 10px 0;
      }
    }
  }
  .bottom {
    flex: 4;
    .container {
      display:flex;
      width: 90%;
      height: 90%;
      margin: auto;
      @include mobile {
        flex-direction: column;
      }

      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      .right {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
      }

      @include mobile {
        width: 100%;
      }
    }
  }
}
