@import "../../../global.scss";

.contact{
    display: flex;

    @include mobile {
        flex-direction: column;
    }

    .left{
        flex:1;
        overflow: hidden;

        img{
            height: 100%;
        }
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            @include mobile {
                height: 95%;

            }
            span{
                color: green;
            }
        }
    }
}