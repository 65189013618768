@import "../../../global.scss";

.about{
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size: 50px;

        @include mobile {
            font-style: 20px;
        }
    }
    
    .container{

        .left{
            display: flex;
            justify-content: center;
            flex: 1;

            .textContainer{
                width: 450px;
                text-align: center;
            }
        }
        .right{
            flex: 1;
            display: flex;

            img{
                height: 100%;
            }
            @include mobile {
                margin-bottom: 20px;
            }
        }
    }

}